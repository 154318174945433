/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-26 11:00:07
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-26 11:36:02
 */
export const columns = [
{
    title: '小区名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '小区照片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'imageUrl'}

},
{
  title: '审核状态',
  dataIndex: 'authStatus',
  key: 'authStatus',
  /*width:150,*/
  align:'center',
  ellipsis:true,
  scopedSlots: { customRender: 'authStatus'}
},
{
    title: '详细地址',
    dataIndex: 'province',
    key: 'province',
    width: 450,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'province'}
},
// {
//     title: '联系人',
//     dataIndex: 'contacts',
//     key: 'contacts',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '联系电话',
//     dataIndex: 'mobile',
//     key: 'mobile',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
]
