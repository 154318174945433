<template>
    <a-card :border="false">
        <div class="realtor">
            <!-- 查询表单 -->
            <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
                <a-row :gutter="8">
                    <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                        <a-form-model-item label="小区名称">
                            <a-input v-model="searchData.name" placeholder="小区名称" allowClear></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :xxl="4" :xl="5" :md="8" sm="2">
                        <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div>
                <a-button type="primary" v-if="isAdd" @click="toHandler('add')">新增</a-button>
                <a-button v-if="selectedRowKeys && selectedRowKeys.length == 1 && isEdit && (selectedRows[0].dealerId == dealerId) && selectedRows[0].authStatus == 1" type="primary" @click="toHandler('edit')">修改</a-button>
                <a-button v-if="selectedRowKeys && selectedRowKeys.length == 1 && isDelete && (selectedRows[0].dealerId == dealerId) && selectedRows[0].authStatus == 1" type="danger" @click="toHandler('del')">删除</a-button>
                <a-button
                  v-if="selectedRowKeys && selectedRowKeys.length > 0 && isSubmit && selectedRows[0].authStatus == 1 && (selectedRows[0].dealerId == dealerId)"
                  type="primary"
                  @click="batchSubmitAudit"
                >提交审核</a-button>
            </div>
            <!-- 数据表格 -->
            <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
                :columns="columns" :data-source="tableData" bordered :pagination="page"
                @change="changeTable" :customRow="changeTableRow">
                <span slot="imageUrl" slot-scope="item">
                    <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
                </span>
                <span slot="province" slot-scope="item, row">
                   {{row.province}}{{row.city}}{{row.area}}{{row.address}}
                </span>
                <span slot="authStatus" slot-scope="text">
                    <a-tag v-if="text == 1" color="#333333">未处理</a-tag>
                    <a-tag v-if="text == 2" color="#e6a23c">待审核</a-tag>
                    <a-tag v-if="text == 3" color="#108ee9">审核通过</a-tag>
                    <a-tag v-if="text == 4" color="#67c23a">审核拒绝</a-tag>
                </span>
            </a-table>
            <!-- 编辑表单 -->
            <DealerStore-edit-modal ref="DealerStoreEditModal" @reload="getDataReload"></DealerStore-edit-modal>
        </div>
    </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerStoreEditModal from './components/DealerStoreEditModal.vue'
import { delDealerStore, listDealerStore, selectByIdDealerStore } from './api/DealerStoreApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
export default {
    name: 'dealerStore',
    components: {
        DealerStoreEditModal,
    },
    data() {
        return {
            columns: columns,
            tableData: [],
            searchData: {}, // 搜索条件
            scroll:{ y: 600 },
            tableLoading: false, //表格loading
            page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
            selectedRowKeys: [],
            selectedRows: [],
            isAdd: checkPermission('community:management:add'),
            isEdit: checkPermission('community:management:edit'),
            isDelete: checkPermission('community:management:delete'),
            isSubmit: checkPermission('community:management:submit'),
            dealerId: this.$store.getters.userInfo.dealerId,
            flagOperation: true
        }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: true,
                // type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods:{

      /**
       * 批量提交审核
       */
      batchSubmitAudit() {
        var _this = this
        this.axios.get('/api/dealer/dealer/dealerInfo/selectCommunitySetByDealerId/'+_this.$store.getters.userInfo.dealerId).then((res) => {
            // 获取经销商操作小区配置
            if(res.body.flagOperation == false){
              //this.flagOperation = false
              _this.$notification.warning({
                message: '无操作小区权限，请先向事务所申请权限！'
              })
            }else{

              const everyResult = this.selectedRows.every((item) => {
                return item.authStatus === 1
              })
              if (everyResult) {
                this.dataList = this.selectedRows
                _this.$confirm({
                  title: '提交审核',
                  content: '确认要提交审核吗?',
                  okText: '确认',
                  cancelText: '取消',
                  async onOk() {
                    _this.tableLoading  = true
                    const query = {
                      ids : _this.dataList.map((item) => item.id)
                    }
                    _this.axios.post('/api/firm/community/batchSubmitAudit', query).then(res => {
                      if(res.code == 200) {
                        _this.$notification.success({ message: res.message })
                        _this.getData()
                      }else {
                        _this.$notification.error({ message: res.message })
                        _this.getData()
                      }

                    })
                  },
                  onCancel() {
                  }
                })
              }else{
                this.$notification.error({ message: '请选择未提交审核记录' })
              }
            }
        })

      },
      //导入
      uploadChange (info) {
        this.tableLoading = true
        setTimeout(()=>{
          if(info.file.response){
            let hint = info.file.response
            if(hint.code === 200) {
              this.tableLoading = false
              this.$message.success(hint.message)
              this.getData()
            }else if(hint.code === 500) {
              this.tableLoading = false
              this.$message.error(hint.message)
            }
            if (info.file.status !== 'uploading') {
              console.log(info.file, info.fileList)
            }
          }
        })

      },
        getDataReload(){
           this.getData()
        },
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            this.selectedRowKeys = []
            this.selectedRows = []
            listDealerStore ({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    const { total, records } = res.body
                    this.page.total = total
                    this.tableData = records
                }
            }).finally(() => this.tableLoading = false)


        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            this.page.current = 1
            this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {}
            this.page.pageSize = 10
            this.page.current = 1
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        console.log(e)
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },
        toHandler(name) {
          const _this = this
          //
          this.axios.get('/api/dealer/dealer/dealerInfo/selectCommunitySetByDealerId/'+_this.$store.getters.userInfo.dealerId).then((res) => {
            // 获取经销商操作小区配置
            if(res.body.flagOperation == false){
              //this.flagOperation = false
              _this.$notification.warning({
                message: '无操作小区权限，请先向事务所申请权限！'
              })
            }else{

              if (name === 'add') {
                _this.$refs.DealerStoreEditModal.setRowData({}, 'add')
                return
              }
              if (_this.selectedRows.length === 0) {
                _this.$notification.warning({
                  message: '请选择一条记录'
                })
                return
              }
              if (_this.selectedRows[0].dealerId != this.$store.getters.userInfo.dealerId) {
                _this.$notification.warning({
                  message: '请选择自己创建的小区'
                })
                return
              }
              switch (name) {
                case 'edit':
                  _this.$refs.DealerStoreEditModal.setRowData(_this.selectedRows[0], 'edit')
                  break
                case 'del':
                  _this.$confirm({
                    title: '删除警告',
                    content: '确认要删除此条记录吗?',
                    okText: '确认',
                    cancelText: '取消',
                    async onOk() {
                      const res = await delDealerStore(_this.selectedRows[0].id)
                      if (res.code === 200) {
                        _this.$notification.success({ message: res.message })
                        _this.getData()
                      } else {
                        _this.$notification.error({ message: res.message })
                      }
                    },
                    onCancel() {}
                  })
                  break
              }
            }

          })


          // if(_this.flagOperation == false){
          //   this.$notification.warning({
          //     message: '无操作小区权限，请先向事务所申请权限！'
          //   })
          //   return
          // }

        },
    },
    created() {
        this.getData()
    }
}
</script>

<style lang="less" scoped>
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }
</style>
